define(['jquery', 'app', 'siteObj', 'common'], function($, app, siteObj) {
  
  var countrySwitcher = {

    selectors: {
      $countrySwitcher: '',
      $countryOption: '',
      $countryName: '',
      $countrySelect: '',
      $countryMenu: ''
    },

    _getCountriesSuccessCallback: function(result, element) {
      $(element).html(result);
      countrySwitcher.bindEvents();

      var countryCode = siteObj.shippingCountry,
        customerLocation = siteObj.customerLocation,
        siteDefaultLocale = siteObj.siteDefaultLocale;

      if (countryCode === null && customerLocation !== '') {
        //if shipping country cookie does not exist - use customerLocation
        countryCode = customerLocation;
      }

      if (countryCode === null && customerLocation === '') {
        //if cookie does not exist and customerLocation is not set - use siteDefaultLocale
        countryCode = siteDefaultLocale.split('_')[1];
      }
      countrySwitcher.getCountry(countryCode);
    },

    getCountries: function(element) {
      $.ajax({
        url: '/' + siteObj.customerLocale + '/' + siteObj.currencyType + '/shippingcountries.nav',
        dataType: 'html',
        success: function(result) {
          countrySwitcher._getCountriesSuccessCallback(result, element);
        },
        error: function(xhr) {
          //console.error("ERROR: Could not retrieve country string template. " + xhr.status + " " + xhr.statusText);
        }
      });
    },

    getCountry: function(countryCode) {
      for (var i = 0, len = this.selectors.$countryOption.length; i < len; i++) {
        var $item = this.selectors.$countryOption.eq(i),
          itemCountryCode = $item.data('countryCode');
        if (countryCode === itemCountryCode) {
          var countryText = $item.text();
          this.setCountry(countryText);
        }
      }
    },
    setCountry: function(country) {
      this.selectors.$countryName.text(country);
    },

    bindEvents: function() {
      var countrySwitcherElement = $('.country-switcher');

      var countrySelectors = {
        $countrySwitcher: countrySwitcherElement,
        $countryOption: countrySwitcherElement.find('.country-option').find('a'),
        $countryName: countrySwitcherElement.find('.country-name'),
        $countrySelect: countrySwitcherElement.find('.country-selected'),
        $countryMenu: countrySwitcherElement.find('.country-submenu')
      };

      countrySwitcher.selectors.$countrySwitcher = countrySelectors.$countrySwitcher;
      countrySwitcher.selectors.$countryOption = countrySelectors.$countryOption;
      countrySwitcher.selectors.$countryName = countrySelectors.$countryName;
      countrySwitcher.selectors.$countrySelect = countrySelectors.$countrySelect;
      countrySwitcher.selectors.$countryMenu = countrySelectors.$countryMenu;

      countrySelectors.$countrySelect.on('click', function() {
        var $this = $(this),
          submenu = $this.next().find('.country-submenu');

        $this.toggleClass('active');
        submenu.toggle();
      });

      $(document).on('click', function(e) {
        var $clicked = $(e.target),
          countrySwitcherClass = $clicked.parents().hasClass('country-switcher'),
          countryOptionClass = $clicked.parents().hasClass('country-option');

        if (!countrySwitcherClass) {
          countrySelectors.$countrySelect.removeClass('active');
          countrySelectors.$countryMenu.hide();
        }

        if (countryOptionClass) {

          var basketCountrySelectorClass = $clicked.parents().hasClass('basket-country-selector'),
            action = '';

          if (basketCountrySelectorClass) {
            action = 'Basket';
          } else {
            action = 'Header';
          }

          try {
            app.publish('tracking/record', 'ShippingCountry', action, $clicked.text());
          } catch (e) {}
        }
      });
    }
  };

  countrySwitcher.getCountries('.countries-st');
  return countrySwitcher;
});
